import api from './api';
import qs from 'qs';

export default {
    getList: async (params) => {
        const data = qs.stringify(params);
        const response = await api.get(`/doctors?${data}`);
        return response.data;
    },
    getById: async (id) => {
        const response = await api.get(`/doctors/${id}`);
        return response.data;
    },
    getDoctorOfficesByDoctorId: async (id) => {
        const response = await api.get(`/doctors/${id}/offices`);
        return response.data;
    },
    save: async (office) => {
        const response = await api.put(`/doctors`, office);
        return response.data;
    },
    saveLinkedOffices: async (doctorsOffices) => {
        const response = await api.put(`/doctors/offices`, doctorsOffices);
        return response.data;
    }
}