<template>
  <div class="doctors">
    <b-form-input v-model="filter" type="text" placeholder="Type to Search" debounce="500"></b-form-input>
    <b-table show-empty striped hover 
      :items="this.fetchData"
      :fields="fields" 
      :current-page="currentPage"
      :per-page="10"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @row-clicked="rowClicked" 
    ></b-table>
    <b-pagination pills
      size="sm"
      v-model="currentPage"
      :total-rows="totalItemsCount"
      :per-page="perPage"
    ></b-pagination>
  </div>
</template>

<script>
import doctorsService from '../services/doctors';

export default {
  name: 'Doctors',
  data: () => {
    return {
      fields: [
        {
          key: 'firstName',
          label: 'First Name',
          sortable: true
        },
        {
          key: 'lastName',
          label: 'Last Name',
          sortable: true
        },
        {
          key: 'locations',
          label: 'Locations',
          sortable: true
        },
        {
          key: 'zoomId',
          label: 'Zoom Id',
          sortable: true
        },
        {
          key: 'schedule',
          label: 'Schedule',
          sortable: true
        },
        {
          key: 'pelvic floor',
          label: 'Pelvic Floor',
          sortable: true
        }
      ],
      currentPage: 1,
      perPage: 10,
      totalItemsCount: 0,
      filter: null,
      sortBy: null,
      sortDesc: false
    };
  },
  methods: {
    async fetchData(params) {
      if (!params) params = { currentPage: this.currentPage, perPage: this.perPage };
      const list = await doctorsService.getList(params);
      this.totalItemsCount = list.totalItemsCount;
      return list.items;
    },
    rowClicked(row) {
      this.$router.push(`/doctors/${row.id}`);
    }
  }
}
</script>